var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "alerts" } }, [
    _vm.alerts && _vm.alerts.length > 0
      ? _c(
          "span",
          _vm._l(_vm.alerts, function (item) {
            return _c(
              "b-alert",
              {
                key: item.value,
                attrs: {
                  variant: item.variant,
                  show: _vm.dismissCountDown,
                  fade: "",
                  dismissible: "",
                },
                on: {
                  dismissed: function ($event) {
                    _vm.dismissCountDown = 0
                  },
                  "dismiss-count-down": _vm.countDownChanged,
                },
              },
              [_vm._v("\n      " + _vm._s(item.value) + "\n    ")]
            )
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }