<template>
  <div id="alerts">
    <span v-if="alerts && alerts.length > 0">
      <b-alert v-for="item in alerts"
               :key="item.value"
               :variant="item.variant"
               :show="dismissCountDown"
               fade
               dismissible
               @dismissed="dismissCountDown = 0"
               @dismiss-count-down="countDownChanged"
      >
        {{ item.value }}
      </b-alert>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AlertManager',
  data: function() {
    return {
      dismissSecs: 2,
      dismissCountDown: 0,
    }
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/getAll',
    }),
  },
  watch: {
    alerts: function() {
      if (this.alerts && this.alerts.length > 0) {
        this.showAlert()
      }
    },
  },
  mounted: function() {
    if (this.alerts && this.alerts.length > 0) {
      this.showAlert()
    }
  },
  methods: {
    dismissed: function(e) {
      const result = this.alerts.filter((item) => item != e)
      this.$store.commit('alerts/setAll', { all: result })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      if (this.dismissCountDown == 0) {
        this.$store.commit('alerts/setAll', { all: [] })
      }
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
  },
}
</script>

<style lang="scss" scoped>
#alerts {
  position: relative;
  top: 50px;
  margin-top: 10px;
  margin-bottom: -10px;
}

.alert {
  border-radius: 0;
  margin-bottom: 0;
}
</style>
